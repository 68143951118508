/* Container for details */
.details-container {
    display: flex;
    flex-direction: column;
}

/* Individual detail */
.detail {
    margin-bottom: 10px; /* Add margin between details */
    border-bottom: 1px solid #ccc; /* Add bottom border for visual separation */
    padding-bottom: 10px; /* Add padding-bottom for spacing */
}

/* Label style */
.label {
    font-weight: bold;
    color: #333;
}

/* Value style */
.value {
    color: #666;
}
