/* style.module.css */

.modal {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    width: 500px; /* Adjust the width as needed */
    height: 100vh; /* Cover the full height of the viewport */
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    transition: right 0.3s ease;
    z-index: 10000; /* Set a high z-index value */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds modal height */
  }
  
  .modal.open {
    right: 0;
  }
  
  .modalContent {
    padding: 20px;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .closeButton {
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .modalBody {
    margin-top: 20px;
  }
  
  .closeButton {
    margin-left: auto; /* Align the close button to the right */
  }
  
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999; /* Set a z-index higher than other components */
  }
  