.ct-table th{
    background-color: #EBF8FE !important;
    color: #0B1A36 !important;
    font-family: "Rubik";
    padding: 20px;
}
.team-card{
    height: calc(100vh - 340px);
    overflow-x: hidden;
    overflow-y: auto;
}
.table-bodyct tr td{
    padding: 20px;
}