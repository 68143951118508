.bannerButtons {font-size: 16px;background: #fecc00;color: #000;padding: 10px 20px;border-radius: 5px;display: flex
    ;
        align-items: center;
        justify-content: center;
        max-width: 232px;}
.bannerlogoFirst{max-width: 350px;}
.subtitle{color: #121212;font-size: 34px;    font-family: Rubik;}
.bannerhad{font-size: 45px; margin-bottom: 5px;margin-top:-17px;font-family: Rubik;}
.bannerbox{text-align: center;font-family: Rubik;}
.bannerbox p{font-size: 16px;}
.buttonBox{display: flex;justify-content: center;}
.h2head{    font-size: 40px;
    font-family: 'Rubik';
    color: #1A3F80;}
 .h4head{       font-size: 18px !important;
    font-weight: 500 !important;
    color: #121212 !important;width: 100% !important;}   

.para2section{font-family: Rubik;font-size: 18px;font-weight: 400;color:#696969} 
.captionFont{
    font-family: Rubik;
    font-size: 25px !important;
    font-weight: 500 !important;
    line-height: 30px;
    text-align: center;
    color: #32BBF9 !important;
    margin-bottom: 0;

    }   
.AiPoweredSection{background: #EBF8FE;padding: 60px 80px 60px 80px;}
 .threeSection{padding: 60px 80px 60px 80px;}
 .imgLeft{border-radius: 20px;}

  .processSection{background:#F9F9F9;padding: 60px 0px 60px 0px;}
  .processIconBx{width:76px; height: 76px; border-radius: 80px !important;box-shadow: 0px 2px 18px 0px #18191C1F;margin: auto;line-height: 76px;   background: #fff;}
  .recruitingSection{background:#fff;padding: 60px 0px 60px 0px;}
  .recuitinghead{font-family: 'Rubik';
    font-size: 45px;
    font-weight: 500;}
    .recPara{font-family: 'Rubik';
        font-size: 18px;
        font-weight: 400;color:#121212;
        }
.hirIcon{    border: 1px solid #FECC00;
    width: 28px;
    height: 28px;
    min-width: 28px;
    position: relative;
    display: flex;border-radius: 2px;;
    text-align: center;
    justify-content: center;
    align-items: center;}
.featuresBox{display: flex;    gap: 20px;    margin-bottom: 30px;}
.featuresinnerBox h5{    font-size: 20px;
    font-weight: 500;
    font-family: 'Rubik';color:#1A3F80;
    }
    .featuresinnerBox p{    font-size: 16px;
        font-weight: 400;
        font-family: 'Rubik';color:#5E5E5E;
        
        }
.CtaSection:before {
    
    position: absolute;
    content: '';
    background: #fecc00;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

}
.opporHead{    font-size: 60px !important;
    font-weight: 500 !important;
    font-family: 'Rubik' !important;color: #fff;}
.opporPara{ font-family: 'Rubik' !important;color: #121212; font-size: 18px !important;}

.ctaButtonsFullbtn{ display: flex;
        max-width: 235px;
        margin: auto;
        border-radius: 8px;
        background: #1A3F80;
        color: #fff;
        border-color: #1A3F80;
        gap: 5px;
        align-items: center;
        justify-content: center;
        height: 44px;
        font-size: 16px;
        font-family: 'Rubik';}
.detailInfoBox{display: flex;gap: 15px;}
.subHeadRecu{font-size: 18px;
    color: #121212;
    font-weight: 500;
    font-family: 'Rubik';}
.recIconBox{box-shadow: 0px 4px 9px 0px #0000000D;
    background: #FEFEFE;
    border-radius: 8px;
    width: 60px;
    height: 60px;
    line-height: 55px;
    min-width: 60px;
    text-align: center;
}
.detailInfoContent h5{    font-size: 30px;
    font-family: 'Rubik';
    font-weight: 500;
    color: #121212;}
    .detailInfoContent p{    font-size: 16px;
        font-family: 'Rubik';
        color: #5E5E5E;
        font-weight: 400;}
.sectionHeading h2{    font-size: 36px;
    font-family: 'Rubik';
    color: #1A3F80;
    font-weight: 500;}
    .sectionHeading h4{   font-size: 18px;
        font-family: 'Rubik';
        color: #121212;
        font-weight: 500;}
.serviceIcon{    width: 60px;
    height: 60px;
    display: flex;
    background: #32BBF9;
    box-shadow: 0px 4px 9px 0px #0000000D;
    border-radius: 8px;min-width: 60px;text-align: center;align-items: center;
    justify-content: center;}
.serviceBox{display: flex;gap: 20px;    }

.serviceIcon img{text-align: center;width: 48px;height: 48px;}
.serviceBox h5{    font-size: 30px;
    font-family: 'Rubik';
    font-weight: 500;
    color: #121212;}
    .serviceBox p{    font-size: 16px;
        font-family: 'Rubik';
        font-weight: 400;
        color: #5E5E5E;}
.sectionHeading{margin-bottom: 30px;}
.clientsSectionBox{    padding: 80px 0px;background: #F9F9F9;}
.testimonialItem{background: #32BBF9;border-radius: 7.5px;    padding: 45px 30px 45px 30px;}
.testimonialSingle{   border-bottom: 1px solid #fff;padding-bottom: 30px;}
.testimonialSingle blockquote{    color: #fff;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 400;}
    .testimonialSingle img{    width: 60px;
        height: 48px;
        margin-bottom: 20px;}
.clientInfo h5{font-size: 20px;
    color: #1A3F80;
    font-weight: 500;
    font-family: 'Rubik';}
    .clientInfo p{color: #fff;font-family: 'Rubik';}
    .testimonialItem .testimonialSingle .blueIcon{display: none;}
    .testimonialItem:hover .testimonialSingle .whiteIcon {
      display: none;
      }
      .testimonialItem:hover .testimonialSingle .blueIcon {
        display: block;
        }  
.testimonialItem:hover{    background: #FEFEFE;
    box-shadow: 11.25px 7.5px 37.5px 0px #0000001A;}
.testimonialItem:hover blockquote{color: #121212;}
.testimonialItem:hover .testimonialSingle{border-bottom: 1px solid #121212;}
.testimonialItem:hover .clientInfo h5{color: #121212;}
.testimonialItem:hover .clientInfo p{color: #121212;}       
.sponsorsSectionBox{padding: 60px 0;background: #ffffff;}
.frequentlyAskedSectionBox{background: #F9F9F9;padding: 60px 0;}
.testimonialHeadingSection{font-size: 50px;color: #000;font-weight: 600;font-family: 'Rubik';}
.workProcessSectionHeading{font-size: 50px;color: #000;font-weight: 600;font-family: 'Rubik';}