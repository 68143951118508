.filterCardBox {
    padding: 0px 20px 10px 20px;
    border-radius: 12px;
    background: #FEFEFE;
    box-shadow: none;
}

.filterCardBox label {

    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    text-align: left;
    color: #080808;
}

.filterCardBox input {
    border: 1px solid #E0E0E0 !important;
    box-shadow: 0px 0px 2px 0px #0000000D;
    background-color: #FEFEFE !important;
    height: 40px;
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;

    color: #9F9F9F;
}

.filterCardBox select {
    border: 1px solid #E0E0E0 !important;
    box-shadow: 0px 0px 2px 0px #0000000D;
    background-color: #FEFEFE !important;
    height: 40px;
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;

    color: #9F9F9F;
}

.listLiveJobs {
    border-radius: 12px;
    padding: 20px;
    border: 1px solid #E0E0E0;
    background: #EBFEEC;
    box-shadow: 0px 2px 18px 0px #18191C08;
    margin-bottom: 15px;
}

.listLiveJobs:hover {
    background-color: #e1f7e2;
}

.listPendingJobs {
    border-radius: 12px;
    padding: 20px;
    border: 1px solid #E0E0E0;
    background: #fefeeb;
    box-shadow: 0px 2px 18px 0px #18191C08;
    margin-bottom: 15px;
}

.listPendingJobs:hover {
    background-color: #e9d68d;
}

.listExpiredJobs {
    border-radius: 12px;
    padding: 20px;
    border: 1px solid #E0E0E0;
    background: #FEEBEB;
    box-shadow: 0px 2px 18px 0px #18191C08;
    margin-bottom: 15px;
}


.listExpiredJobs:hover {
    background-color: #E5C3C4;
}

.filterCardBox .secrchIcon {
    font-size: 19px;
    position: absolute;
    left: 13px;
    top: 26px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--vz-secondary-color);
    height: 40px;
}

.btnprimaryfilter {
    background: #1a3f80;
    border-radius: 8px;
    width: 150px !important;
    display: block;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Rubik';
    color: #fff;
}

.companyLogobox img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border: 1px solid #E3E3E3;
    background: #F5F5F5;
    border-radius: 4px;
}


.companyLogobox span {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border: 1px solid #E3E3E3;
    background: #F5F5F5;
    border-radius: 4px;
}

.companyMainbox {
    display: flex;
    gap: 10px;
}

.aboutCompanyname .listTitle {
    font-family: 'Rubik';
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 2px;

    color: #1A3F80;
}

.aboutCompanyname .listTitle a {
    color: #1A3F80;
}

.listSubTitle {
    font-family: Rubik;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

    color: #121212;
}


.paraExInfo {
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    color: #696969;
}

.badgePending {
    background: #ffcc00;
    max-width: 200px;
    text-align: center;
    color: white;
    font-size: 12px;
    font-family: 'Rubik';
    text-transform: uppercase;
    height: 20px;
    line-height: 20px;
    border-radius: 4px;
    margin-bottom: 0;
}


.badgeLive {
    background: #34C759;
    max-width: 70px;
    text-align: center;
    color: #FFF;
    font-size: 12px;
    font-family: 'Rubik';
    text-transform: uppercase;
    height: 20px;
    line-height: 20px;
    border-radius: 4px;
    margin-bottom: 0;
}

.editBtnRight {
    text-align: right;
}

.editBtnRight a i {
    font-size: 20px;
    color: #5E5E5E;
}

.alertJobcolose {
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    width: 90px;
    background: #EA43351A;
    color: #EA4335;
    font-family: 'Rubik';
    text-align: center;
    font-weight: 500;
    margin-left: auto;
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 25px;
    cursor: pointer;
    margin-bottom: 10px;
}

.alertJobLive {
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    width: 90px;
    background: #e6e3d8;
    color: #34c759;
    font-family: 'Rubik';
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    margin-left: auto;
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 25px;
    margin-bottom: 10px;
}

.postedDate {
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: right;
    color: #696969;
    margin-bottom: 0;
}