.pageDetailsBox {
    border-radius: 8px;
    background: #1A3F80;
    padding: 15px 24px;
    margin-top: 40px;
}

.pageDetailsBox h5 {
    font-family: 'Rubik';
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #FEFEFE;
}

.pageDetailsBox p {
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;
    color: #CCCCCC !important;
    margin-bottom: 0px;
}

.postNewJob label {
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    text-align: left;
    color: #080808;
}

.postNewJob input,
.postNewJob select,
.postNewJob textarea {
    box-shadow: 0px 2px 2px 0px #0000000D;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #121212;
}

.buttonNext {
    padding: 12px 60px 12px 60px;

    border-radius: 12px;
    line-height: 21.6px;
    background: #1A3F80;
    font-family: Rubik;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #fff;
}

.formsSectionBox {
    box-shadow: 0px 12px 24px 0px #0D0D0D0F;
    padding: 20px;
    border-radius: 12px;
}


.cancelBtn {
    border: 1.5px solid #1A3F80;
    font-family: 'Rubik';
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: center;
    color: #1A3F80;
    background: transparent;
    padding: 12px 60px 12px 60px;
    border-radius: 12px;
}

.cancelBtn:hover {
    background: #1A3F80;
    color: #fff;
}


.addJobQustionbtn {
    padding: 12px 30px 12px 30px;
    border-radius: 12px;
    line-height: 21.6px;
    background: #1a3f80;
    font-family: Rubik;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    color: #fff;
    float: right;
}


.AiJDbtn {
    padding: 12px 30px 12px 30px;
    border-radius: 12px;
    line-height: 21.6px;
    background: #1a3f80;
    font-family: Rubik;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    color: #fff;
    float: left;
}

.buttonNext:hover {
    border: 1.5px solid #1A3F80;
    color: #1A3F80;
}

.qualifiSection {
    box-shadow: 0px 2px 2px 0px #0000000D;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #121212;
}

.btnRemove {
    background: #DD403C;
    padding: 0;
    width: 44px;
    height: 44px;
}

.btnRemove i {
    font-size: 26px;
    color: #fff;
}

.cardPreviewtop {
    border-bottom: 1px solid #32BBF9;
    box-shadow: none;
    margin-bottom: 0px;
}

.jobtitleReview {
    font-family: 'Rubik';
    font-size: 20px;
    font-weight: 500 !important;
    line-height: 24px;
    color: #1A3F80;
}

.nameofjobs {
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    color: #121212;
}

.loctionbox {
    text-align: right;
}

.cardskillsTop {
    border-bottom: 1px solid #32BBF9;
    box-shadow: none;
    margin-bottom: 0px;
}

.keySkillsList {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
}

.keySkillsList li {
    display: inline-block;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: #121212;
}

.cardDescription {
    border-bottom: 1px solid #32BBF9;
    box-shadow: none;
}

.descriptionPara {
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;

    color: #121212;
}

.cardrighttside {
    border-left: 1px solid #32BBF9;
    box-shadow: none;
    margin-top: 10px;
    border-radius: 0;
}

.righttsidetitale {
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: left;

    color: #32BBF9;
}

.overviewTablebox tr {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #32BBF9;
    padding-top: 10px;
}

.overviewTablebox tr td {
    border: none;
    padding: 0;
}

.overViewhd {
    font-family: 'Rubik';
    font-size: 18px;
    font-weight: 500 !important;
    line-height: 24px;
    text-align: left;
    color: #1A3F80 !important;
    padding: 0px 0 !important;
}

.overpara {
    font-family: 'Rubik';
    font-size: 15px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 10px 0 !important;
    color: #121212 !important;
}

.cardrighttside .card-body {
    padding-right: 0px;
}