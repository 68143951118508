/* style.module.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10001;
}

.modal {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  padding: 20px;
  transition: width 0.3s ease, height 0.3s ease;
}

.dragging {
  cursor: move;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerText {
  margin: 0;
}

.headerHr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.modalBody {
  margin-top: 10px;
}

.perviousBox .pageItem{width: auto !important;background: transparent;border: none;display: flex
  ;align-items: center;}
  .nextBox .pageItem{width: auto !important;background: transparent;border: none;display: flex
    ;align-items: center;}  
    .paginationList li a{    border-radius: 8px !important;
      height: 40px !important;
      width: 40px !important;
      padding: 0 !important;
      background-color: transparent !important;
      border: 1px solid transparent !important;
      line-height: 40px;
      text-align: center;
      color: #080808 !important;
      font-size: 14px;
      font-weight: 400;
      font-family: 'Rubik';}

      .paginationList li.active .page {
        color: #fff;
        background-color: #6691e7 !important;
        border-color: #6691e7 !important;
    } 

    .perviousBox .pageItem, .nextBox .pageItem{color: #080808 !important;
      font-family: 'Rubik';
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
    }
    .perviousBox .pageItem:hover, .nextBox .pageItem:hover{background: transparent !important;}
    .paginationList{    gap: 5px;}
     
