/* Container for details */
.detailscontainer {
    display: flex;
    flex-wrap: wrap;
}

/* Individual detail */
.detail {
    width: 100%; /* Each detail takes up 100% of the container */
    padding: 10px; /* Adds padding around each detail */
    box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
     /* Adds a bottom border for visual separation */
}

/* Label style */
.detail strong {
    font-weight: bold;
    color: #333; /* Label text color */
}

/* Value style */
.detail span {
    color: #666; /* Value text color */
}

/* Media query for responsiveness */
@media screen and (min-width: 768px) {
    .detail {
        width: 50%; /* Each detail takes up 50% of the container on larger screens */
    }
}
.candidateSideopenStyle h3{
    font-family: 'Rubik';
    font-size: 28px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #1A3F80;
    padding-bottom: 15px;
    }
.modalCandidateBox{display: block;}
.whatppBox{border-bottom: 1px solid #b8c3d8;border-top: 1px solid #b8c3d8;}
.whatppBox .detail{width: 100%;display: flex;justify-content: center;align-items: center;gap: 10px;    flex-direction: row;}


.whatppBox .detail .labeleHead{
    font-family: 'Rubik';
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #1A3F80;margin-bottom: 0;
    }

.WhatappButton{background-color: #34C759 !important;
    padding: 8px 12px;
    border-radius: 8px;
    
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
   
   }    
   .WhatappButton i{font-size: 18px;}   

.EmailButton{background-color: #1A3F80 !important;
    padding: 8px 12px;
    border-radius: 8px;
   
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;margin-left: 10px;}   

 .detailBox{display: block;    flex-direction: column;}  
 .labeleHead{    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1A3F80 !important;    margin-bottom: 10px;    display: block;} 
.valueLable{font-family: 'Rubik';
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #080808 !important;    display: block;}
.detailscontainer.detailscontainerBox .detailBox:first-child {width: 100%;}
.detailscontainer.detailscontainerBox .detailBox:nth-child(2) {width: 100%;}
.detailscontainer.detailscontainerBox .detailBox:nth-child(10) {width: 100%;}
.detailscontainer.detailscontainerBox .detailBox:nth-child(16) {width: 100%;}
.detailscontainer.detailscontainerBox .detailBox:last-child{width: 100%;    }
.detailscontainer.detailscontainerBox .detailBox:nth-child(3) .valueLable {border: 1px solid #E3E3E3;border-radius: 8px;
    padding: 8px 12px;display: inline-block;}
.detailscontainer.detailscontainerBox .detailBox:nth-child(4) .valueLable, .detailscontainer.detailscontainerBox .detailBox:nth-child(5) .valueLable, .detailscontainer.detailscontainerBox .detailBox:nth-child(6) .valueLable, .detailscontainer.detailscontainerBox .detailBox:nth-child(7) .valueLable,.detailscontainer.detailscontainerBox .detailBox:nth-child(8) .valueLable, .detailscontainer.detailscontainerBox .detailBox:nth-child(9) .valueLable, .detailscontainer.detailscontainerBox .detailBox:nth-child(10) .valueLable, .detailscontainer.detailscontainerBox .detailBox:nth-child(11) .valueLable, .detailscontainer.detailscontainerBox .detailBox:nth-child(12) .valueLable,.detailscontainer.detailscontainerBox .detailBox:nth-child(13) .valueLable, .detailscontainer.detailscontainerBox .detailBox:nth-child(14) .valueLable, .detailscontainer.detailscontainerBox .detailBox:nth-child(15) .valueLable, .detailscontainer.detailscontainerBox .detailBox:nth-child(17) .valueLable,.detailscontainer.detailscontainerBox .detailBox:nth-child(18) .valueLable,.detailscontainer.detailscontainerBox .detailBox:nth-child(19) .valueLable, .detailscontainer.detailscontainerBox .detailBox:nth-child(20) .valueLable, .detailscontainer.detailscontainerBox .detailBox:nth-child(21) .valueLable, .detailscontainer.detailscontainerBox .detailBox:nth-child(22) .valueLable, .detailscontainer.detailscontainerBox .detailBox:nth-child(23) .valueLable {border: 1px solid #E3E3E3;border-radius: 8px;
        padding: 8px 12px;display: inline-block;}    
.detailscontainer.detailscontainerBox .detailBox:nth-child(16) .valueLable a{color: #32BBF9;}        

.profileEditvalue{background: #1A3F80;
    padding: 8px 12px;
    border-radius: 8px;
    color: #fff;
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;

    }

    .detailscontainer.detailscontainerBox .detailBox:last-child .valueLable{background: #E8ECF2;padding: 12px;
        border-radius: 8px;color: #606060 !important;border: 1px solid #B8C3D8}    