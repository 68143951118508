.authBgCover{background: radial-gradient(50% 128% at 50% 50%, #AFE7FF 0%, #EBF9FF 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}
.cardLoginBox{padding: 40px;background: #EBF8FE;border: 1px solid #BFEAFD;box-shadow: 0px 10px 20px 0px #0D0D0D0D;border-radius: 20px;
}
.loginLeftContentBox p{display: flex;
        gap: 5px;
        font-family: 'Rubik';
        font-size: 17px;
        font-weight: 400;
        line-height: 21.6px;
        text-align: left;
        color: #5E5E5E;}
        .loginLeftContentBox p strong{ font-family: 'Rubik';
            font-size: 18px;
            font-weight: 700;}       
.loginLeftContentBox h3{  margin-bottom: 30px;  font-family: 'Rubik';
    font-size: 31px;
    font-weight: 500;
    line-height: 37.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #121212;}

.badgebox{background: #FFEFB0;border-radius: 100px;padding: 8px 24px;font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #6B5600;
    margin-top: 40px;
    }
.logoBusinessBox{display: flex;justify-content: space-between;margin-top: 20px;}
.logoBusinessBox .logoBox{background: #fff;
    width: 105px;
    height: 35px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;}
.loginRightHed h5{font-family: 'Rubik';
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    
    color: #1A3F80;}
    .loginRightHed p{
        font-family: 'Rubik';
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color:#5E5E5E}
.loginFormBox .formLogin div input{ background: #fff;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 16px 20px;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    
    color: #929292;
}        
    .forgotPasswordBox{margin-top: 3px;}
    .forgotPasswordBox a{    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1A3F80;}
.loginSigninButton{padding: 16px 24px;
    border-radius: 8px;
    background: #1A3F80;
    width: 100%;
    color: #F9F9F9;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
   
}