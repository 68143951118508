.ourClientsSecton {
    box-shadow: 0px 0px 30px 0px #523F690D;
    background: #ffffff;
    padding: 20px;
    border-radius: 15px;
    margin-top: 20px;
}

.applicationTitle h4 {
    font-family: 'Rubik';
    font-size: 20px;
    font-weight: 500;
    color: #121212;
}

.applicationTitle {
    border-bottom: 0.8px solid #485E9029;
    padding: 5px 0;
}


.mrb20 {
    margin-bottom: 20px;
}


.clientCardBox {
    background: #32BBF9;
    padding: 20px 15px;
    border-radius: 15px;
    margin-bottom: 20px;
    min-height: 280px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.clientCardBox h4 {
    font-family: 'Rubik';
    font-size: 28px;
    font-weight: 500;
    line-height: 33.18px;

    color: #080808B2;
}

.clientInfoBox {
    list-style: none;
    padding-left: 0;
    width: 80%;
}

.clientInfoBox li {
    width: 50%;
    float: left;
}

.clientInfoBox .spanPerson {
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 400;
    color: #121212;
    display: block;
}

.clientInfoBox .spanName {
    font-family: 'Rubik';
    font-size: 18px;
    font-weight: 500;
    color: #FEFEFE;
}


.secondBoxBg {
    background: #FECC00;
}

.filterCardBox {
    padding: 0px 20px 10px 20px;
    border-radius: 12px;
    background: #FEFEFE;
    box-shadow: none;
}

.filterCardBox label {

    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    text-align: left;
    color: #080808;
}

.filterCardBox input {
    border: 1px solid #E0E0E0 !important;
    box-shadow: 0px 0px 2px 0px #0000000D;
    background-color: #FEFEFE !important;
    height: 40px;
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;

    color: #9F9F9F;
}

.filterCardBox select {
    border: 1px solid #E0E0E0 !important;
    box-shadow: 0px 0px 2px 0px #0000000D;
    background-color: #FEFEFE !important;
    height: 40px;
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;

    color: #9F9F9F;
}


.clientMenuBtnSection {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 90px;
    right: -20px;
    gap: 15px;
    background: #1A3F80;
    width: 40px;
    text-align: center;
    border-radius: 12px 0px 0px 12px;
    padding: 12px 30px 12px 15px;
}

.clientMenuBtnSection a i {
    color: #F9F9F9;
    font-size: 20px;
}
.filter-box{
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
}
.reset-btn{
    border: 1px solid #32BBF9;
    font-family: "Rubik";
    font-size: 14px;
    color: #32BBF9;
}
.reset-btn:hover{
    border: 1px solid #32BBF9;
    font-family: "Rubik";
    color: #fff;
    font-size: 14px;
    background-color: #32BBF9;
}
.search-btn{
    border: 1px solid #1A3F80;
    font-family: "Rubik";
    color: #fff;
    font-size: 14px;
    background-color: #1A3F80;
}
.search-btn:hover{
    border: 1px solid #1A3F80;
    font-family: "Rubik";
    font-size: 14px;
    color: #fff;
    background-color: #1A3F80;
}
.addnew-btn{
    border: 1px solid #32BBF9;
    font-family: "Rubik";
    font-size: 14px;
    color: #fff;
    background-color: #32BBF9; 
}
.addnew-btn:hover{
    border: 1px solid #32BBF9;
    font-family: "Rubik";
    color: #fff;
    font-size: 14px;
    background-color: #32BBF9; 
}
.details-list{
    position: absolute;
    right: 0;
    top: 50px;
    list-style: none;
    background-color: #00000091;
    padding: 10px 10px;
    border-radius: 20px 0 0 20px;
}
.details-list li{
    padding: 5px 0 !important;
}
.details-list li a{
    color: #fff;
}