.team-title{
    font-family: "Rubik";
    font-size: 22px;
    color: #1A3F80 !important;
    font-weight: 500;
}
.main-title{
    font-family: "Rubik";
    font-size: 16px;
    font-weight: 500;
    color: #1A3F80;
    margin-bottom: 5px !important;
}
.label-ct{
    font-family: "Rubik";
    font-size: 12px;
}