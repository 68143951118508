.navbarMenu{background: #FCFCFC;
    border: 1px solid #F1F1F1;
    box-shadow: 0px 10px 20px 0px #0D0D0D0D !important;
    width: var(--vz-vertical-menu-width);
    z-index: 1002;
    background: var(--vz-vertical-menu-bg);
    border-right: 1px solid var(--vz-vertical-menu-border);
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    -webkit-box-shadow: var(--vz-vertical-menu-box-shadow);
    box-shadow: var(--vz-vertical-menu-box-shadow);
    padding: 0 0 calc(70px + 25px) 0;
    -webkit-transition: all .1s ease-out;
    transition: all .1s ease-out;
    /* background-color: #576165; */
    /* background-color: #5E95DD; */
    background-image: linear-gradient(to right, #6691e7, #6691e7);
   
    }

  .containerfluid{padding: 0;}  