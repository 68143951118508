.headerOpen {
    left: 290px !important;

}

.headerClose {
    /* margin-right: 25px; */
    left: 25px !important;
    /* top: 20px !important; */
    border-radius: 15px;
    /* box-shadow: 0px 2px 18px 0px #18191C1F; */
}

.NewDownloadBtn {
    border-radius: 8px;
    background-color: #1A3F80 !important;
}

.userIconBox {
    gap: 15px;
}

.userNameFont {
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500 !important;
    color: #5E5E5E;
}

.userProfileText {
    font-size: 13px !important;
    font-family: 'Rubik';
    font-weight: 300;
    color: #B1B1B1 !important;
}

.openClosebtn span:nth-child(1) {
    width: 100%;
}

.openClosebtn span:nth-child(3) {
    width: 100%;
}

.openClosebtn span:nth-child(2) {
    width: 60%;
    right: 0;
    left: auto;
}

.openClosebtn span:nth-child(2)::before {
    position: absolute;
    content: '';
    left: -8px;
    top: -4px;
    width: 4px;
    height: 4px;
    border-right: solid 5px #878a99;
    border-bottom: solid 5px transparent;
    border-top: solid 5px transparent;

}

.stickytop {
    margin-top: 20px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 2px 18px 0px #18191C1F;
    margin-right: 23px;
}

.notificationModalBox{border-radius: 12px;    padding: 10px !important;    width: 600px;}
.badgeSpan{background-color: #1a3f80;}
.notificationheadrBox{background: #E8ECF2;    border-radius: 6px;}
.notificationheadrBox .navItemLink{font-family: 'Rubik';
    font-size: 15px;border-radius: 6px;
    font-weight: 400;
    line-height: 20px;
    
    text-align: center;
    
    color: #414141 !important;}

  .notiHead{font-family: 'Rubik';
    font-size: 17px !important;
    font-weight: 500 !important;
    line-height: 21.6px;
    text-align: left;
    color: #121212;}
.msgPara{font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #5E5E5E;}
.notificationInnerBox{    gap: 10px;}
.notifIconbox img{width: 44px;}
