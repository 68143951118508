.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.ReactCrop__image {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}


/*Scroll bar*/
/* / width / */
::-webkit-scrollbar {
  width: 1px;
  height: 5px;
}
/* / Track / */
::-webkit-scrollbar-track {
  /* background: #ffffff; */
  /* border-radius: 10px; */
  /* -webkit-border-radius: 10px; */
  background-size: contain;
  background: rgba(28, 104, 226, 0.1);
  border-radius: 100vw;
  margin-block: 0.5em;
}
/* / Handle / */
::-webkit-scrollbar-thumb {
  /* background: #0a8ccb; */
  border-radius: 100vw;
  background-image: linear-gradient(to left, #6691e7, #1f5294);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  height: 4px;
}
/* / Handle on hover / */
::-webkit-scrollbar-thumb:hover {
  background: #0c6692;
  cursor: pointer;
}
/*end Scroll bar*/



.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
}

.organizationChartBox {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #f8f9fa; /* Optional */
}

.form-select.search {
  padding-left: 30px;
}

.form-control.search {
  padding-left: 30px;
}


.organizationChartBox div {
  cursor: grab;
  user-select: none; /* Prevent text selection while dragging */
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  min-height: 150px;
}

.quill.is-invalid {
  border: 0.5px solid red;
  border-radius: 0.25rem; 
}
/* .container-fluid{
  width: 1250px !important;
} */
/*Profile*/
.profile-menu{
  list-style: none;
  padding: 15px;
}
.profile-menu .nav-item{
  margin: 10px 0;
}
.profile-menu .nav-link.active, .profile-menu .show>.nav-link{
	background: rgb(254, 204, 6);
  background: linear-gradient(90deg, rgb(254 204 6 / 23%) 54%, rgb(255 245 209 / 0%) 100%);
  padding: 10px;
  font-family: "Rubik";
  font-size: 17px !important;
  border-radius: 5px 0 0 5px;
  color: #ffcd00;
  font-weight: 400;
}
.profile-menu .nav-link{
  padding: 10px;
  font-family: "Rubik";
  font-size: 17px !important;
  border-radius: 5px 0 0 5px;
  color: #000;
  font-weight: 400;
}
.ct-breadcrumb .breadcrumb-item.active{
  color: #1A3F80 !important;
  font-family: "Rubik";
}
.navct-tabs .nav-item.show .nav-link, .navct-tabs .nav-link.active{
  color: #1A3F80;
  border: 1px solid #1A3F80;
  border-radius: 5px !important;
  font-family: "Rubik";
}
.navct-tabs .nav-link{
  color: #929292;
  border: 1px solid #929292;
  background-color: #F9F9F9;
  font-family: "Rubik";
  border-radius: 5px !important;
}
.cp-4{
  padding: 4px;
}
.title-main{
  color: #1A3F80;
  font-family: "Rubik";
  font-size: 24px;
}
.main-title{
  color: #1A3F80;
  font-family: "Rubik";
  font-size: 20px;
  font-weight: 500;
}
.breadcrumb li a{
  font-family: "Rubik";
}
.download-table-xls-button{
  background-color: #1A3F80;
  border: 0;
  border-radius: 5px;
  font-family: "Rubik";
}
.download-table-xls-button:hover{
  background-color: #1A3F80;
  color: #ffcd00;
  border: 0;
  border-radius: 5px;
  font-family: "Rubik";
}
.thead-ctm th{
  background-color: #EBF8FE !important;
  font-family: "Rubik";
  color: #000 !important;
}
.customReportClorButton{
  background-color: #1A3F80;
  border: 0;
  border-radius: 5px;
  font-family: "Rubik";
}
.customReportClorButton:hover{
  background-color: #1A3F80;
  color: #ffcd00;
  border: 0;
  border-radius: 5px;
  font-family: "Rubik";
}
.dwnbtn{
  background-color: #1A3F80;
  border: 0;
  color: #fff;
  border-radius: 5px;
  font-family: "Rubik";
}
.dwnbtn:hover{
  background-color: #1A3F80;
  color: #ffcd00;
  border: 0;
  border-radius: 5px;
  font-family: "Rubik";
}
.FiltterButton{
  border:1px solid #1A3F80 !important;
  color: #1A3F80;
  border: 0;
  border-radius: 5px;
  font-family: "Rubik";
}
.FiltterButton:hover{
  background-color: #1A3F80;
  color: #fff;
  color: #ffcd00;
  border: 0;
  border-radius: 5px;
  font-family: "Rubik";
}
.ctlabel{
  font-family: "Rubik";
  font-weight: 500;
}
.upload-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 10px;
}
.edit-btnop{
  color: #1A3F80;
  font-family: "Rubik" !important;
}
.upload-imgop{
    width: 4.2rem;
}
.rounded-10{
  border-radius: 10px !important;
}
.textarea-ct textarea{
  height: 10px !important;
}
.ct-card{
  height: calc(100vh - 230px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}