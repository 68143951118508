.crm-card{
    background-color: #fff;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: calc(100vh - 180px);
    overflow-y: auto;
}
.custom-card{
    display: flex;
    justify-content: flex-end;
}
.btn-add{
    font-family: "Rubik";
    background-color: #32BBF9;
    border-radius: 5px;
    border: 0;
    font-weight: 400;
}
.btn-add:hover{
    font-family: "Rubik";
    background-color: #1A3F80;
    border-radius: 5px;
    border: 0;
    font-weight: 400;
}
.input-crm{
    border-radius: 5px !important;
}
.crm-title{
    font-family: "Rubik";
    color: #1A3F80;
    font-size: 20px;
    font-weight: 500;
}
.crm-lists tr td{
    font-family: "Rubik";
}
.table-head{
    border-radius: 10px;
}
.table-ctm thead tr th{
    background-color: #EBF8FE;
    color: #1A3F80;
}
.active-badge{
    border: 1px solid #B6B6B6;
    text-transform: capitalize;
    padding: 2px 10px;
    border-radius: 5px;
    color: #1A3F80;
    font-family: "Rubik";
    font-size: 12px;
    font-weight: 500;
    max-width: 100px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.active-badge::before{
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: rgb(11, 102, 35);
    position: absolute;
    left: 5px;
}
.inactive-badge{
    border: 1px solid #B6B6B6;
    text-transform: capitalize;
    padding: 2px 10px;
    border-radius: 5px;
    color: #1A3F80;
    font-family: "Rubik";
    font-size: 12px;
    font-weight: 500;
    max-width: 100px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inactive-badge::before{
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50px;
    position: absolute;
    background-color: rgb(174, 4, 4);
    left: 5px;
}
.adduser-btn{
    font-family: "Rubik";
    background-color: #1A3F80;
    color: #fff;
    border-radius: 5px;
    border: 0;
    font-weight: 400; 
}
.adduser-btn:hover{
    font-family: "Rubik";
    background-color: #1A3F80;
    color: #fff;
    border-radius: 5px;
    border: 0;
    font-weight: 400; 
}
.cancel-btn{
    font-family: "Rubik";
    border: 1px solid #1A3F80 !important;
    background-color: #fff;
    color: #1A3F80;
    border-radius: 5px;
    border: 0;
    font-weight: 400;
}
.cancel-btn:hover{
    font-family: "Rubik";
    border: 1px solid #1A3F80 !important;
    background-color: #1A3F80;
    color: #fff;
    border-radius: 5px;
    border: 0;
    font-weight: 400;
}
.form-labelct{
    font-family: "Rubik";
    font-weight: 500;
}
.ct-card{
    border-radius: 10px !important;
    padding-top: 20px;
}
.crm-titlec{
    font-family: "Rubik";
    font-size: 16px;
    color: #1A3F80;
}