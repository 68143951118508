/* components/Modal1.module.css */

/* .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  width: 40%;

} */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
  /* Set a higher z-index value */
}

.modal::-webkit-scrollbar {
  width: 10px;
}

.modal {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-height: 80vh;
  /* Set a maximum height, adjust as needed */
  overflow-y: auto;
  /* Add scroll if content exceeds the maximum height */
  width: 40%;

}

.closeButton {
  /* background: #008bd2; */
  /* border: 2px solid #008bd2; */
  border: none !important;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  border-radius: 50%;
  /* padding: 5px; */
  width: 30px;
  height: 30px;

}

.closeButton:hover {
  background: #fff;
  /* color: #008bd2; */

}

.modalHeader {
  text-align: center;
}

.headerText {
  font-size: 14px;
  /* Add any additional styling for the header text if needed */
}
.modaelHed{text-align: left;
  font-family: 'Rubik';
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #1A3F80;
  margin-bottom: 0;}

.headerHr {
  /* Add any additional styling for the header hr if needed */
}
.modalSectionBox .modal{border-radius: 20px;}

.modalBody {
  /* Add any additional styling for the body section if needed */
}
.resumeBodyTitle{font-size: 10px;}

.modalSectionBox label {
  font-family: 'Rubik';
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: left;
  color: #080808;
}
.modalSectionBox input{
  box-shadow: 0px 2px 2px 0px #0000000D;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #121212;
}
.modalSectionBox select{
  box-shadow: 0px 2px 2px 0px #0000000D;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #121212;
}
.modalSectionBox textarea{
  box-shadow: 0px 2px 2px 0px #0000000D;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #121212;
}
.modalSectionBox .modal{max-width: 900px !important; min-width: 850px !important; max-height: inherit;top: 100px !important;
  left: auto !important;
 
  right: auto !important;    position: absolute;}
  .modalSectionBox .headerHr{border-top:none ;margin-bottom: 0;}
.overlay.modalSectionBox{position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex
;
  justify-content: center;
  align-items: center;
  z-index: 99999999;    overflow: auto;}
@media only screen and (min-width: 320px) and (max-width: 580px){
  .modal{  max-height: 95% !important;}

}


/* Add this style to your CSS */
.dragging {
cursor: move;
}
.closeButton{color: #667085;
  background: transparent;}
.newModalInnerBox{background: #f3f3f3;
  box-shadow: 0px 4px 48px 20px #00000014;}
.innerMOdalBox{background-color: #fff;padding: 20px;border-radius: 24px;}
.innerMOdalBox .closeButton{top: 30px;right: 40px;font-size: 25px;}