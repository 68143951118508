.customFooterSection{background-color: #0B1A36;padding: 60px 0;}
.footerPara p{color: #fff;    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 400;}
.footerLogoBx img{    width: 300px;
    height: 100px;
    object-fit: contain;}
    .footerLogoBx {    margin-bottom: 20px;}
.footerTitle{ font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500;color: #fff;}
   .footerSectionInnerStyle ul li a{    color: #fff;
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 400;} 
.copyrightFooter{background: #0B1A36;
    } 
.copyrightFooter p{color: #fff;} 
.copyrightFooter p a{color: #fff;}       