.innerTitlebox{font-size: 30px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #2B3674;
    font-family: 'Rubik';}
.cardPostJobBox{    border-radius: 16px;
    box-shadow: 0px 10px 20px 0px #0D0D0D0D;
    border: 1px solid #E0E0E0;} 
.navItemli{display: flex;align-items: center;}    
.navText{  background: #fff;
    z-index: 99;
    padding: 5px 18px 5px 10px;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    
    color: #606060;}    
 