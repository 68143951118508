.progress {
  width: 90px;
  height: 90px;
  font-size: 20px;
  color: #000;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: #fff;
  text-align: center;
  line-height: 90px;
  font-weight: 500;

}

.progress::after {
  content: "%";
}

.progress .title {
  position: relative;
  z-index: 100;
}

.progress .overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #fff;
}

.progress .left,
.progress .right {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 6px solid #1a3f80;
  border-radius: 100px 0px 0px 100px;
  border-right: 0;
  transform-origin: right;
}

.progress .left {
  animation: load1 1s linear forwards;
}

.progress:nth-of-type(2) .right,
.progress:nth-of-type(3) .right {
  animation: load2 .5s linear forwards 1s;
}

.progress:last-of-type .right,
.progress:first-of-type .right {
  animation: load3 .8s linear forwards 1s;
}

@keyframes load1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes load2 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }

  100% {
    z-index: 100;
    transform: rotate(270deg);
  }
}

@keyframes load3 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }

  100% {
    z-index: 100;
    transform: rotate(315deg);
  }
}

.cardBox {
  box-shadow: 0px 0px 30px 0px #523F690D;
  padding: 15px 14px;
}

.gridTitleStyle {
  font-family: 'Rubik';
  font-size: 16px;
  font-weight: 400;
  color: #5E5E5E;
  text-align: left;
}

.gridCountStyle {
  font-family: 'Rubik';
  font-size: 25px;
  font-weight: 500;
  color: #1A3F80;
  text-align: left;
}


.applicationStatisticsBox {
  box-shadow: 0px 0px 30px 0px #523F690D;
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
}

.applicationTitle h4 {
  font-family: 'Rubik';
  font-size: 20px;
  font-weight: 500;
  color: #121212;
}

.applicationTitle {
  border-bottom: 0.8px solid #485E9029;
  padding: 5px 0;
}

.applicationMap img {
  width: 100%;
  min-height: 375px;
  object-fit: contain;
}

.applicationMap {
  margin-top: 10px;
}

.progressBox {
  margin-top: 10px;
}

.overinfo {
  display: flex;
  align-items: center;
  gap: 20px;
}

.colorbox span {
  width: 15px;
  height: 15px;
  display: flex;
  background: red;
  border-radius: 100%;
}

.overviewListItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.8px solid #E0E0E0;
  background: #F9F9F9;
  padding: 14px 28px 14px 28px;
  border-radius: 8px;
}

.countbox {
  background: #1D3B6F1A;
  width: 50px;
  text-align: center;
  border-radius: 30px;
}

.overname {
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 400;
}

.overviewListSection {
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  height: 347px;
  overflow: auto;
}

.overviewListSection::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.Bgcolor1 span {
  background: #1D3B6F;
}

.Bgcolor2 span {
  background: #217AA2;
}

.Bgcolor3 span {
  background: #D9AE00;
}

.Bgcolor4 span {
  background: #FE0000;
}

.Bgcolor5 span {
  background: #25CC00;
}

.Bgcolor6 span {
  background: #730C67;
}

.Bgcolor7 span {
  background: #FF5733;
}

.Bgcolor8 span {
  background: #8E44AD;
}

.Bgcolor9 span {
  background: #2ECC71;
}

.color1 {
  color: #1D3B6F;
}

.color2 {
  color: #217AA2;
}

.color3 {
  color: #D9AE00;
}

.color4 {
  color: #FE0000;
}

.color5 {
  color: #25CC00;
}

.color6 {
  color: #730C67;
}

.color7 {
  color: #FF5733;
}

.color8 {
  color: #8E44AD;
}

.color9 {
  color: #2ECC71;
}


.Bgcolor1 {
  background-color: #1D3B6F;
}
.Bgcolor2 {
  background-color: #217AA2;
}
.Bgcolor3 {
  background-color: #D9AE00;
}
.Bgcolor4 {
  background-color: #FE0000;
}
.Bgcolor5 {
  background-color: #25CC00;
}
.Bgcolor6 {
  background-color: #730C67;
}
.Bgcolor7 {
  background-color: #FF5733;
}
.Bgcolor8 {
  background-color: #8E44AD;
}
.Bgcolor9 {
  background-color: #2ECC71;
}

.progress-bar {
  height: 20px;
  border-radius: 5px;
  transition: width 0.3s ease;
}

.calanderSection img {
  width: 100%;
}

.calanderSection {
  background: #fff;
  padding: 10px 15px;
  border-radius: 15px 15px 0px 0px;
}

.mrb20 {
  margin-bottom: 20px;
}

.remindersSectionBox {
  background: #fff;
  padding: 28px 20px;
  border-radius: 0px 0px 15px 15px;
}

.remindersSectionBox h4 {
  font-family: 'Rubik';
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.monthBox {
  background: #1A3F80;
  color: #B8C3D8;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.dateDayBox {
  width: 45px;
  background: #fff;
  border-radius: 6px;
}

.nameTimeBox h3 {
  font-family: 'Rubik';
  font-size: 13.23px;
  font-weight: 500;
  line-height: 15.88px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.nameTimeBox p {
  font-family: 'Rubik';
  font-size: 8.27px;
  font-weight: 400;
  line-height: 9.93px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 0;
}

.activeBtn {
  padding: 3.31px 6.62px 3.31px 6.62px;

  border-radius: 3.31px 0px 0px 0px;
  opacity: 0px;
  background: #0BA02C1A;
  color: #0BA02C;
  font-family: 'Rubik';
  font-size: 10px;
  font-weight: 600;
  line-height: 9.93px;
  text-align: left;

  text-transform: uppercase;
}

.remindersListBox {
  display: flex;
  gap: 5px;
  justify-content: space-between;

  background: #EBF8FE;
  padding: 10px;
  box-shadow: 0px 1.65px 14.89px 0px #18191C08;
  border-radius: 6px;
  margin-bottom: 10px;
}

.dayDateBox h5 {
  display: block;
  font-family: 'Rubik';
  font-size: 15px;
  font-weight: 500;
  line-height: 17.87px;
  text-align: center;
  /* text-underline-position: from-font; */
  text-decoration-skip-ink: none;
  color: #1A3F80;
  margin-bottom: 0;
}

.dayDateBox h5 span {
  display: block;
  font-family: 'Rubik';
  font-size: 10.75px;
  font-weight: 400;
  line-height: 12.9px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
}

.activebtnBox {
  display: flex;
  align-items: flex-end;
  margin-left: 8px;
}

.nameTimeBox {
  margin-top: 10px;
}

.remindersListSectionBox {
  position: relative;
  height: 216px;
  overflow: auto;
}

.remindersListSectionBox::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}


.ourClientsSecton {
  box-shadow: 0px 0px 30px 0px #523F690D;
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  margin-top: 20px;
}

.clientCardBox {
  background: #32BBF9;
  display: flow-root;
  padding: 20px 15px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.clientCardBox h4 {
  font-family: 'Rubik';
  font-size: 28px;
  font-weight: 500;
  line-height: 33.18px;

  color: #080808B2;
}

.clientInfoBox {
  list-style: none;
  padding-left: 0;
}

.clientInfoBox li {
  width: 50%;
  float: left;
}

.clientInfoBox .spanPerson {
  font-family: 'Rubik';
  font-size: 13px;
  font-weight: 400;
  color: #121212;
  display: block;
}

.clientInfoBox .spanName {
  font-family: 'Rubik';
  font-size: 18px;
  font-weight: 500;

  color: #FEFEFE;
}

.secondBoxBg {
  background: #FECC00;
}

.calendarBoxSet {
  border: none !important;
  margin-bottom: 20px;
}




.setsize {
  font-size: 6em;
}

.chartscontainer:after {
  clear: both;
  content: "";
  display: table;
}

.piewrapper {
  height: 1em;
  width: 1em;
  float: left;
  background: #ddd;
  border-radius: 100%;

  position: relative;
}

.piewrapper:nth-child(3n+1) {
  clear: both;
}

.piewrapper .pie {
  height: 100%;
  width: 100%;
  clip: rect(0, 1em, 1em, 0.5em);
  left: 0;
  position: absolute;
  top: 0;
}

.piewrapper .pie .halfcircle {
  height: 100%;
  width: 100%;
  border: 0.1em solid #3498db;
  border-radius: 50%;
  clip: rect(0, 0.5em, 1em, 0);
  left: 0;
  position: absolute;
  top: 0;
}

.piewrapper .label {
  background: #ffffff;
  border-radius: 50%;
  bottom: 0.4em;
  color: #000000;
  cursor: default;
  display: block;
  font-size: 0.25em;
  left: 0.4em;
  line-height: 2.6em;
  position: absolute;
  right: 0.4em;
  text-align: center;
  top: 0.4em;
}

.piewrapper .label .smaller {
  color: #000;
  font-size: .45em;
  padding-bottom: 20px;

}

.piewrapper .shadow {
  height: 100%;
  width: 100%;
  border: 0.1em solid #bdc3c7;
  border-radius: 50%;
}

.piewrapper.style2 .label {
  background: none;
  color: #7f8c8d;
}

.piewrapper.style2 .label .smaller {
  color: #bdc3c7;
}

.piewrapper.progress30 .pie .rightside {
  display: none;
}

.piewrapper.progress30 .pie .halfcircle {
  border-color: #1a3f80;
}

.piewrapper.progress30 .pie .leftside {
  transform: rotate(108deg);
}

.piewrapper.progress60 .pie {
  clip: rect(auto, auto, auto, auto);
}

.piewrapper.progress60 .pie .rightside {
  transform: rotate(180deg);
}

.piewrapper.progress-60 .pie .halfcircle {
  border-color: #e8bc52;
}

.piewrapper.progress60 .pie .leftside {
  transform: rotate(216deg);
}

.piewrapper.progress90 .pie {
  clip: rect(auto, auto, auto, auto);
}

.piewrapper.progress90 .pie .rightside {
  transform: rotate(180deg);
}

.piewrapper.progress-90 .pie .halfcircle {
  border-color: #e67e22;
}

.piewrapper.progress90 .pie .leftside {
  transform: rotate(324deg);
}

.piewrapper.progress45 .pie .rightside {
  display: none;
}

.piewrapper.progress45 .pie .halfcircle {
  border-color: #e8bc52;
}

.piewrapper.progress45 .pie .leftside {
  transform: rotate(162deg);
}

.piewrapper.progress75 .pie {
  clip: rect(auto, auto, auto, auto);
}

.piewrapper.progress75 .pie .rightside {
  transform: rotate(180deg);
}

.piewrapper.progress75 .pie .halfcircle {
  border-color: #32BBF9;
}

.piewrapper.progress75 .pie .leftside {
  transform: rotate(270deg);
}

.piewrapper.progress95 .pie {
  clip: rect(auto, auto, auto, auto);
}

.piewrapper.progress95 .pie .rightside {
  transform: rotate(180deg);
}

.piewrapper.progress95 .pie .halfcircle {
  border-color: #e74c3c;
}

.piewrapper.progress95 .pie .leftside {
  transform: rotate(342deg);
}

.piewrappersolid {
  border-radius: 50%;
  overflow: hidden;
}

.piewrappersolid:before {
  border-radius: 0 100% 100% 0%;
  content: '';
  display: block;
  height: 100%;
  margin-left: 50%;
  transform-origin: left;
}

.piewrappersolid .label {
  background: transparent;
}

.piewrappersolid.progress-65 {
  background: linear-gradient(to right, #e67e22 50%, #34495e 50%);
}

.piewrappersolid.progress-65:before {
  background: #e67e22;
  transform: rotate(126deg);
}

.piewrappersolid.progress-25 {
  background: linear-gradient(to right, #9b59b6 50%, #34495e 50%);
}

.piewrappersolid.progress-25:before {
  background: #34495e;
  transform: rotate(-270deg);
}

.piewrappersolid.progress-88 {
  background: linear-gradient(to right, #3498db 50%, #34495e 50%);
}

.piewrappersolid.progress-88:before {
  background: #3498db;
  transform: rotate(43.2deg);
}
.pdr{padding-right: 0px;}



@media only screen and (min-width: 1200px) and (max-width: 1425px) {
  .gridTitleStyle{font-size: 14px;}
  .gridCountStyle{font-size: 22px;}
  .remindersSectionBox h4 {font-size: 18px;}
}


@media only screen and (min-width: 1200px) and (max-width: 1342px) {
  
  .remindersSectionBox h4 {font-size: 16px;}
}