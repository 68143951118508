.cardCandidateInfo {
    border-radius: 15px;
    padding: 15px;
}

.cardheadertitle .leftheaderbox h4 {
    font-family: 'Rubik';
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

    color: #1A3F80;
    margin-bottom: 0;
}

.cardheadertitle .leftheaderbox {
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.rightheaderbox button {
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: #1A3F80;
    color: #F9F9F9;
}

.livePreviewBox label {
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    text-align: left;

    color: #080808;
}

.livePreviewBox input {
    box-shadow: 0px 2px 2px 0px #0000000D;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;

    color: #121212;
}

.livePreviewBox select,
.livePreviewBox textarea {
    box-shadow: 0px 2px 2px 0px #0000000D;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;

    color: #121212;
}

.pdl {
    padding-left: 3px;
}

.pdr {
    padding-right: 3px;
}

.cancelButton {
    border: 1.5px solid #1A3F80;
    font-family: 'Rubik';
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: center;
    color: #1A3F80;
    background: transparent;
}

.cancelButton {
    background: #1A3F80;
    color: #fff;
}

.addLeadButton {
    border: 1.5px solid #1A3F80;
    font-family: 'Rubik';
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: center;
    color: #ffffff;
    background: #1A3F80;
}

.fileInputBtn {
    position: relative;
}

.spanInput {
    position: absolute;
    top: 1px;
    left: 2px;
    width: 93%;
    background: #ffffff;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    gap: 5px;
    color: #878a9c;
    font-weight: 400;
}

.spanInput i {
    background: #ddd;
    padding: 5px;
    border-radius: 20px;
    margin-left: 11px;
    font-size: 14px;
    font-weight: 600;
}

.fileAddInput {
    position: relative;
}

.fileInputBtn input[type="file"] {}

.rightheaderbox button:hover {
    border: 1px solid #1a3f80;
    background: #2754a2;
    color: #fff;
}

.leftFilterSection {
    background: #FEFEFE;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid #E0E0E0;
    position: sticky;
    top: 100px;
}

.filterTitlebox h5 {
    display: flex;
    justify-content: space-between;
}

.filterTitlebox h5 .span1 {
    font-family: 'Rubik';
    font-size: 23px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #121212;
}

.filterTitlebox h5 .span2 {
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;

    color: #1A3F80;
}

.filterTitlebox {
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 20px;
}

.filterInnerbox {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.filterInnerbox div label {
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    text-align: left;
    color: #080808;
}

.filterInnerbox div input {
    background: #FEFEFE;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 2px 2px 0px #0000000D;
    border-radius: 8px;
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;
}

.filterInnerbox div select {
    border: 1px solid #E0E0E0;
    box-shadow: 0px 2px 2px 0px #0000000D;
    border-radius: 8px;
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 400;
    color: #9F9F9F;
}

.accordionButton {
    background: #ffff !important;
    padding: 8px !important;
    color: #080808 !important;
    border: 1px solid #1A3F80;
    font-family: 'Rubik';
    font-size: 15px;
    font-weight: 500 !important;
    line-height: 19.2px;
    border-radius: 8px !important;
}

.customCheckbox {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}

.customCheckbox label {
    margin-bottom: 0;
    font-family: 'Rubik' !important;
    font-size: 13px !important;
    ;
    font-weight: 400 !important;
    ;
    line-height: 15.6px !important;
    ;

    color: #414141 !important;
}

.showMoreBtn {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #07689F;
}

.accordionBody {
    padding-left: 10px;
    padding-right: 10px;
}

.filterInnerbox .toppos {
    top: 13px;
}

.paginationSection .customPaginationStyle {
    float: inherit;
}

.rightSection {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 5px;
}

.paginationSection {
    margin-left: 0px;
    background: #fff;
    margin-right: 0;
    margin-block-end: 20px;
    padding: 20px;
    border-radius: 12px;
}

.selectBox select {
    width: 100px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #ddd;
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 400;
}

.box1,
.box2 {
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    text-align: left;

    color: #080808;
}


.downloadeSection {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    border-top: 1px solid #E0E0E0;
    padding-top: 10px;
}

.downloadeSection button {
    border: 2px solid #32BBF9;
    color: #2EAAE3;
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    text-align: center;
    background: transparent;
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.downloadeSection button i {
    font-size: 20px;
}


.clientSectionLeft {
    background: transparent;
    padding: 0 !important;
    box-shadow: none;
}

.clientSectionLeft .cardbody {
    padding: 0;
}

.clientSectionCard {
    box-shadow: 0px 2px 18px 0px #18191C08 !important;
    border-radius: 20px;
    padding: 30px;
}

.proName {
    font-family: 'Rubik';
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #1A3F80;
}

.contactdiv i {
    height: 24px;
    width: 24px;
    position: relative;
    display: inline-block;
    text-align: center;
    line-height: 24px;
    background: #1A3F80;
    color: #fff;
    border-radius: 6px;
}

.contactdiv {
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    margin-bottom: 8px;
    color: #121212;
}

.ViewProfiebtn {
    padding: 10px 24px;
    background: var(--Colors-Green, #34c759);
    color: #fff !important;
    border-radius: 8px;
    width: 80%;
    border: 1px solid #34c759;
    font-family: Rubik;
    font-size: 16px !important;
    font-weight: 500;
    line-height: 19.2px;
    text-align: center;
    margin-top: 10px;
}

.divinfobox {
    display: flex;
    gap: 4px;
    flex-direction: column;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #080808;
    margin-bottom: 10px;
}

.hdinfo {
    font-family: 'Rubik';
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #414141;
}

.profileAbotContact {
    margin-bottom: 20px;
}

.leftSectionCard {
    border-right: 1px solid #E0E0E0;
}

.infoLocationBox {
    background: #EBF8FE;
    border: 1px solid #BFEAFD;
    padding: 8px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.h6box {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1C6789;
}

.h6box i {
    color: #1C6789;
    width: 20px;
    height: 20px;
    display: inline-block;
    font-size: 20px;
}

.clientMenuBtnSection {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 90px;
    right: -20px;
    gap: 15px;
    background: #1A3F80;
    width: 40px;
    text-align: center;
    border-radius: 12px 0px 0px 12px;
    padding: 12px 30px 12px 15px;
}

.clientMenuBtnSection a i {
    color: #F9F9F9;
    font-size: 20px;
}

.rightSectionInfobx {
    padding-right: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.rupeeIcon {
    border: 1px solid #1C6789;
    border-radius: 30px;
    font-size: 18px !important;
}

.clientSectionCard:hover {
    border: 1px solid #ddd !important;
}


.btncanclePrf {
    border: 2px solid #1A3F80;
    color: #1A3F80;
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    text-align: center;
    background: transparent;
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 6px;
    width: 50%;
    justify-content: center;
}


.btninterviewPrf {
    border: 2px solid #1A3F80;
    color: #1A3F80;
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    text-align: center;
    background: transparent;
    border-radius: 8px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: center;
    text-decoration: none;
}

.view-history {
    position: relative;
    display: inline-block;
    transition: transform 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

.view-history:hover {
    transform: translateX(5px);
    /* Slightly move the text to the right */
    color: #218838;
    /* Darker green on hover */
}

.googleMeetIcon {
    width: 20px;
    /* Adjust the size */
    height: 20px;
    /* Adjust the size */
    margin-right: 2px;
    /* Adds space between the icon and text */
}

.hiringStatusWrapper {
    display: inline-flex;
    align-items: center;
    /* Ensures the text and link are aligned vertically */
}

.btnSearchPrf {
    border: 2px solid #1A3F80;
    color: #fff;
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    text-align: center;
    background: #1A3F80;
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 6px;
    width: 50%;
    text-align: center;
    justify-content: center;
}

.gap10 {
    gap: 10px;
}

.searchResultBox .spanB1 {
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #080808;

}

.searchResultBox .spanB2 {
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #080808;

}

.searchResultBox .spanB3 {
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #080808;

}

.searchResultBox .spanB4 {
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #1A3F80;
    display: flex;
    align-items: center;
}

.searchResultBox {
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 8px 0px;
}

.topIcoin {
    font-size: 20px;
}

.saveSearchSection {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.saveSearchSection button {
    border: 2px solid #32BBF9;
    color: #2EAAE3;
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    text-align: center;
    background: transparent;
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.midSection {
    display: flex;
    align-items: center;
    padding: 8px 0px;
}

.midSection {
    color: #1A3F80;
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.paraBottom {
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 400;
    line-height: 15.6px;
    color: #5E5E5E;
    margin-top: 10px;

}

.paraBottomRight {
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: 400;
    line-height: 15.6px;
    color: #5E5E5E;
    margin-top: 10px;
    text-align: right;
}

.nameAndIdBox {
    display: flex;
    justify-content: space-between;
}

.nameAndIdBox .leftInfobx label {
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500 !important;
    line-height: 19.2px;
    text-align: left;
}

.spanColoerPopup {
    color: #1A3F80;
}

.nameAndIdBox .rightInfobx label {
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500 !important;
    line-height: 19.2px;
    text-align: left;
}

.spanColoerPopup2 {
    color: #2EAAE3;
}

@media only screen and (min-width: 1200px) and (max-width: 1447px) {
    .rightheaderbox button {
        font-size: 14px;
    }

}

/* Container for details */
.detailscontainer {
    display: flex;
    flex-wrap: wrap;
}

/* Individual detail */
.detail {
    width: 100%;
    /* Each detail takes up 100% of the container */
    padding: 10px;
    /* Adds padding around each detail */
    box-sizing: border-box;
    /* Ensures padding and border are included in the element's total width and height */
    /* Adds a bottom border for visual separation */
}

/* Label style */
.detail strong {
    font-weight: bold;
    color: #333;
    /* Label text color */
}

/* Value style */
.detail span {
    color: #666;
    /* Value text color */
}

/* Media query for responsiveness */
@media screen and (min-width: 768px) {
    .detail {
        width: 50%;
        /* Each detail takes up 50% of the container on larger screens */
    }
}

.candidateSideopenStyle h3 {
    font-family: 'Rubik';
    font-size: 28px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #1A3F80;
    padding-bottom: 15px;
}

.modalCandidateBox {
    display: block;
}

.whatppBox {
    border-bottom: 1px solid #b8c3d8;
    border-top: 1px solid #b8c3d8;
}

.whatppBox .detail {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
}


.whatppBox .detail .labeleHead {
    font-family: 'Rubik';
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #1A3F80;
    margin-bottom: 0;
}

.WhatappButton {
    background-color: #34C759 !important;
    padding: 8px 12px;
    border-radius: 8px;

    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;

}

.WhatappButton i {
    font-size: 18px;
}

.EmailButton {
    background-color: #1A3F80 !important;
    padding: 8px 12px;
    border-radius: 8px;

    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    margin-left: 10px;
}

.detailBox {
    display: block;
    flex-direction: column;
}

.labeleHead {
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1A3F80 !important;
    margin-bottom: 10px;
    display: block;
}

.valueLable {
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #080808 !important;
    display: block;
}

.detailscontainer.detailscontainerBox .detailBox:first-child {
    width: 100%;
}

.detailscontainer.detailscontainerBox .detailBox:nth-child(2) {
    width: 100%;
}

.detailscontainer.detailscontainerBox .detailBox:nth-child(10) {
    width: 100%;
}

.detailscontainer.detailscontainerBox .detailBox:nth-child(16) {
    width: 100%;
}

.detailscontainer.detailscontainerBox .detailBox:last-child {
    width: 100%;
}

.detailscontainer.detailscontainerBox .detailBox:nth-child(3) .valueLable {
    border: 1px solid #E3E3E3;
    border-radius: 8px;
    padding: 8px 12px;
    display: inline-block;
}

.detailscontainer.detailscontainerBox .detailBox:nth-child(4) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(5) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(6) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(7) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(8) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(9) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(10) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(11) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(12) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(13) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(14) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(15) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(17) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(18) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(19) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(20) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(21) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(22) .valueLable,
.detailscontainer.detailscontainerBox .detailBox:nth-child(23) .valueLable {
    border: 1px solid #E3E3E3;
    border-radius: 8px;
    padding: 8px 12px;
    display: inline-block;
}

.detailscontainer.detailscontainerBox .detailBox:nth-child(16) .valueLable a {
    color: #32BBF9;
}

.profileEditvalue {
    background: #1A3F80;
    padding: 8px 12px;
    border-radius: 8px;
    color: #fff;
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;

}

/* .detailscontainer.detailscontainerBox .detailBox:last-child .valueLable { */
.detailscontainer.detailscontainerBox .detailBox:first-child .valueLable {

    background: #E8ECF2;
    padding: 12px;
    padding-bottom: 0px;
    border-radius: 8px;
    color: #606060 !important;
    border: 1px solid #B8C3D8
}